import React, { useState, useEffect } from "react";
import Application from "components/Cohort/Application";
import Helmet from "react-helmet";

import { auth } from "api/firebase";
import SignUp from "components/Auth/SignUp";

import {
  RegistrationHeader,
  Button,
  Pricing,
  RegistrationPricing
} from "components/Cohort/styles";
const apply = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    let userData = JSON.parse(sessionStorage.getItem("userData"));
    setData(userData);
  }, []);

  return (
    <>
      <Helmet
        title="Cohort Registration | Enlight"
        meta={[
          {
            name: "description",
            content:
              "Enlight Cohorts are two week programs that teach you to code the apps and websites you've always wanted to make — even if you've had no prior experience."
          },
          {
            name: "og:title",
            content: "Cohort | Enlight"
          },
          {
            name: "og:description",
            content:
              "Enlight Cohorts are two week programs that teach you to code the apps and websites you've always wanted to make — even if you've had no prior experience."
          },
          { name: "og:url", content: "https://enlight.nyc/cohort" },
          {
            name: "og:image",
            content: "https://enlight.nyc/img/cohort/cohort-social.png"
          },
          {
            name: "twitter:title",
            content: "Cohort | Enlight"
          },
          {
            name: "twitter:description",
            content:
              "Enlight Cohorts are two week programs that teach you to code the apps and websites you've always wanted to make — even if you've had no prior experience."
          },
          {
            name: "twitter:image",
            content: "https://enlight.nyc/img/cohort/cohort-social.png"
          }
        ]}
      />

      <RegistrationHeader>
        <main>
          <div className="titles">
            <div>
              <h1>Learn by doing.</h1>
              <h2>
                <span>We want to invest in your success.</span>
              </h2>
              <br /> <br />
            </div>
          </div>

          <RegistrationPricing>
            <Pricing>
              <br />
              <br />
              <br />
              <div>
                <h2>Enlight Scholar</h2>
                <h4>Cohort #3: August 13th - August 27th</h4>

                <p className="price">
                  {" "}
                  <span className="compare">$100</span>{" "}
                  <span className="cost">$10</span>{" "}
                  <span className="tag">one time</span>
                </p>

                <ul>
                  <li>
                    Build 4 <i>real</i> projects
                  </li>
                  <li>10 exclusive webinars</li>
                  <li>Community chat & forum</li>
                  <li>Project recommendations</li>
                  <li>Certificate of completion</li>
                </ul>
                <br />
                <i>Applications close August 13th. Limited spots — act soon.</i>
              </div>
            </Pricing>
          </RegistrationPricing>
        </main>
      </RegistrationHeader>
      {data ? null : (
        // <Application type="scholar" userData={data} />
        <>
          <h2 style={{ textAlign: "center", color: "#333", marginTop: "32px" }}>
            Please sign up to apply to Enlight Cohorts.
          </h2>
          <SignUp text={false} cohort={true} />
        </>
      )}
    </>
  );
};

export default apply;
